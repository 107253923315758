import { FC, memo } from 'react';
import { CommonIconProps } from './types';

const CloseRound: FC<CommonIconProps> = ({ size = 14, color = 'currentColor' }) => {
  return (
    <svg width={size} height={size} viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="m4.303 12.565 8.263-8.262a2.028 2.028 0 1 0-2.869-2.869L1.435 9.697a2.028 2.028 0 0 0 2.868 2.868Z"
        fill={color}
      />
      <path
        d="m1.435 4.303 8.263 8.263a2.028 2.028 0 0 0 2.868-2.869L4.303 1.435a2.028 2.028 0 1 0-2.868 2.868Z"
        fill={color}
      />
    </svg>
  );
};

export default memo(CloseRound);
