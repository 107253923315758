import { FC, memo, useMemo } from 'react';
import nextId from 'react-id-generator';
import { CommonIconProps } from './types';

const Trash: FC<CommonIconProps> = ({ size, color = 'var(--trash-icon-stroke-color, currentColor)', ...rest }) => {
  const id = useMemo(() => nextId(), []);
  return (
    <svg
      width={size || 24}
      height={size || 24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <g clipPath={`url(#${id})`} fill={color || 'currentColor'}>
        <path d="M19.5 5.33h-2.58A4.17 4.17 0 0 0 12.83 2h-1.66a4.17 4.17 0 0 0-4.09 3.33H4.5A.83.83 0 0 0 4.5 7h.83v10.83A4.17 4.17 0 0 0 9.5 22h5a4.17 4.17 0 0 0 4.17-4.17V7h.83a.83.83 0 0 0 0-1.67Zm-8.33-1.66h1.66a2.5 2.5 0 0 1 2.36 1.66H8.81a2.5 2.5 0 0 1 2.36-1.66ZM17 17.83a2.5 2.5 0 0 1-2.5 2.5h-5a2.5 2.5 0 0 1-2.5-2.5V7h10v10.83Z" />
        <path d="M10.33 17a.83.83 0 0 0 .84-.83v-5a.83.83 0 0 0-1.67 0v5a.83.83 0 0 0 .83.83ZM13.67 17a.83.83 0 0 0 .83-.83v-5a.83.83 0 0 0-1.67 0v5a.83.83 0 0 0 .84.83Z" />
      </g>
      <defs>
        <clipPath id={id}>
          <path fill="#fff" transform="translate(2 2)" d="M0 0h20v20H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default memo(Trash);
