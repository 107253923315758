import { FC, memo } from 'react';
import { CommonIconProps } from '../types';

const CheckBox: FC<CommonIconProps> = ({ size = 28, color = 'var(--check-box-icon-color, currentColor)' }) => {
  return (
    <svg width={size} height={size} viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M5.14481 0.5H18.8591C21.4063 0.5 23.502 2.64666 23.502 5.33333V18.6667C23.502 21.3533 21.4063 23.5 18.8591 23.5H5.14481C2.59761 23.5 0.501953 21.3533 0.501953 18.6667V5.33333C0.501953 2.64666 2.59761 0.5 5.14481 0.5Z"
        fill="white"
        stroke={color || 'currentColor'}
      />
    </svg>
  );
};

export default memo(CheckBox);
