import { FC, memo } from 'react';
import { CommonIconProps } from './types';

const Help: FC<CommonIconProps> = ({ size, color = '#fff', ...rest }) => {
  return (
    <svg
      width={size || 24}
      height={size || 24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M12 2C6.486 2 2 6.486 2 12s4.486 10 10 10 10-4.486 10-10S17.514 2 12 2Zm0 1.5c4.703 0 8.5 3.797 8.5 8.5 0 4.703-3.797 8.5-8.5 8.5A8.489 8.489 0 0 1 3.5 12c0-4.703 3.797-8.5 8.5-8.5ZM12 7a1 1 0 1 0 0 2 1 1 0 0 0 0-2Zm-.012 3.49a.75.75 0 0 0-.738.76v5.5a.751.751 0 1 0 1.5 0v-5.5a.75.75 0 0 0-.762-.76Z"
        fill={color}
        fillOpacity=".6"
      />
    </svg>
  );
};

export default memo(Help);
