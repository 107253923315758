import { FC, memo } from 'react';
import { CommonIconProps } from './types';

const Visibility: FC<CommonIconProps> = ({ size = 24, color = 'currentColor' }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      data-testid="password-eye-button"
    >
      <circle className="stroke" cx="12" cy="12" r="3.4" stroke={color} strokeWidth="1.2" />
      <path
        d="M20.188 13.0657L19.7249 12.6843L20.188 13.0657ZM20.188 10.9343L19.7249 11.3157L20.188 10.9343ZM20.7703 12H21.3703H20.7703ZM19.7249 12.6843C19.0327 13.5246 17.932 14.7157 16.5745 15.6924C15.2122 16.6724 13.6426 17.4 12 17.4V18.6C13.9931 18.6 15.8075 17.7225 17.2753 16.6665C18.7479 15.607 19.9232 14.331 20.6512 13.4472L19.7249 12.6843ZM12 17.4C10.3574 17.4 8.78775 16.6724 7.42553 15.6924C6.06804 14.7157 4.96725 13.5246 4.2751 12.6843L3.34884 13.4472C4.07678 14.331 5.25213 15.607 6.72471 16.6665C8.19255 17.7225 10.0069 18.6 12 18.6V17.4ZM4.2751 11.3157C4.96725 10.4754 6.06804 9.28429 7.42553 8.30763C8.78775 7.32757 10.3574 6.6 12 6.6V5.4C10.0069 5.4 8.19255 6.2775 6.72471 7.33354C5.25214 8.393 4.07678 9.66903 3.34884 10.5528L4.2751 11.3157ZM12 6.6C13.6426 6.6 15.2122 7.32757 16.5745 8.30763C17.932 9.28429 19.0327 10.4754 19.7249 11.3157L20.6512 10.5528C19.9232 9.66903 18.7479 8.393 17.2753 7.33354C15.8075 6.2775 13.9931 5.4 12 5.4V6.6ZM4.2751 12.6843C4.07002 12.4353 3.95903 12.2981 3.89056 12.1833C3.83749 12.0943 3.82973 12.0508 3.82973 12H2.62973C2.62973 12.6172 3.00411 13.0286 3.34884 13.4472L4.2751 12.6843ZM3.34884 10.5528C3.00411 10.9714 2.62973 11.3828 2.62973 12H3.82973C3.82973 11.9492 3.83749 11.9057 3.89056 11.8167C3.95903 11.7019 4.07002 11.5647 4.2751 11.3157L3.34884 10.5528ZM20.6512 13.4472C20.9959 13.0286 21.3703 12.6172 21.3703 12H20.1703C20.1703 12.0508 20.1625 12.0943 20.1094 12.1833C20.041 12.2981 19.93 12.4353 19.7249 12.6843L20.6512 13.4472ZM19.7249 11.3157C19.93 11.5647 20.041 11.7019 20.1094 11.8167C20.1625 11.9057 20.1703 11.9492 20.1703 12H21.3703C21.3703 11.3828 20.9959 10.9714 20.6512 10.5528L19.7249 11.3157Z"
        fill={color}
      />
    </svg>
  );
};

export default memo(Visibility);
