import { FC, memo } from 'react';
import { CommonIconProps } from './types';

const Clipboard: FC<CommonIconProps> = ({ size = 20, color = '#1E1D20' }) => {
  return (
    <svg width={size} height={size} fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12.5 16.7H4.2A4.2 4.2 0 0 1 0 12.5V4.2A4.2 4.2 0 0 1 4.2 0h8.3a4.2 4.2 0 0 1 4.2 4.2v8.3a4.2 4.2 0 0 1-4.2 4.2Zm-8.3-15a2.5 2.5 0 0 0-2.5 2.5v8.3A2.5 2.5 0 0 0 4.2 15h8.3a2.5 2.5 0 0 0 2.5-2.5V4.2a2.5 2.5 0 0 0-2.5-2.5H4.2ZM20 15.8V5a.8.8 0 1 0-1.7 0v10.8a2.5 2.5 0 0 1-2.5 2.5H5A.8.8 0 1 0 5 20h10.8a4.2 4.2 0 0 0 4.2-4.2Z"
        fill={color}
      />
    </svg>
  );
};

export default memo(Clipboard);
