import { FC, memo, useMemo } from 'react';
import nextId from 'react-id-generator';
import { CommonIconProps } from './types';

const Card: FC<CommonIconProps> = ({ width = 21, height = 21, color = 'currentColor', ...rest }) => {
  const id = useMemo(() => nextId(), []);

  return (
    <svg width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath={`url(#${id})`} fill={color}>
        <path d="M21.375 3.75H2.625A2.628 2.628 0 0 0 0 6.375v11.25a2.628 2.628 0 0 0 2.625 2.625h18.75A2.628 2.628 0 0 0 24 17.625V6.375a2.628 2.628 0 0 0-2.625-2.625Zm-18.75 1.5h18.75a1.126 1.126 0 0 1 1.126 1.125v1.5H1.5v-1.5A1.126 1.126 0 0 1 2.625 5.25Zm18.75 13.5H2.625A1.126 1.126 0 0 1 1.5 17.625v-8.25h21v8.25a1.127 1.127 0 0 1-1.125 1.125Z" />
        <path d="M17.25 13.927h-.75a.75.75 0 0 1-.75-.75v-.75a.75.75 0 0 1 .75-.75h.75a.75.75 0 0 1 .75.75v.75a.75.75 0 0 1-.75.75Z" />
      </g>
      <defs>
        <clipPath id={id}>
          <path fill="#fff" d="M0 0h24v24H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default memo(Card);
