import { FC, memo, useMemo } from 'react';
import nextId from 'react-id-generator';
import { CommonIconProps } from './types';

const UploadFile: FC<CommonIconProps> = ({ size, color = 'var(--upload-file-icon-color, currentColor)', ...rest }) => {
  const id = useMemo(() => nextId(), []);
  return (
    <svg
      width={size || 32}
      height={size || 32}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <g clipPath={`url(#${id})`} fill={color || 'currentColor'}>
        <path d="M13.17 24.16a4 4 0 0 0 5.66 0l4.28-4.28A1.33 1.33 0 0 0 21.23 18l-3.9 3.9V1.33A1.33 1.33 0 0 0 16 0a1.33 1.33 0 0 0-1.33 1.33l-.02 20.55L10.77 18a1.33 1.33 0 1 0-1.88 1.89l4.28 4.27Z" />
        <path d="M30.67 21.33a1.33 1.33 0 0 0-1.34 1.34V28A1.33 1.33 0 0 1 28 29.33H4A1.33 1.33 0 0 1 2.67 28v-5.33a1.33 1.33 0 0 0-1.34-1.34A1.33 1.33 0 0 0 0 22.67V28a4 4 0 0 0 4 4h24a4 4 0 0 0 4-4v-5.33a1.33 1.33 0 0 0-1.33-1.34Z" />
      </g>
      <defs>
        <clipPath id={id}>
          <path fill="#fff" d="M0 0h32v32H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default memo(UploadFile);
