import { FC, memo } from 'react';
import { CommonIconProps } from './types';

const File: FC<CommonIconProps> = ({ size, color = 'var(--file-icon-color, currentColor)', ...rest }) => {
  return (
    <svg
      width={size || 24}
      height={size || 24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M15 14a1 1 0 0 1-1 1H6a1 1 0 1 1 0-2h8a1 1 0 0 1 1 1Zm-4 3H6a1 1 0 0 0 0 2h5a1 1 0 1 0 0-2Zm9-6.52V19a5 5 0 0 1-5 5H5a5 5 0 0 1-5-5V5a5 5 0 0 1 5-5h4.52a6.96 6.96 0 0 1 4.95 2.05l3.48 3.49A6.95 6.95 0 0 1 20 10.48Zm-6.95-7.02c-.31-.3-.67-.56-1.05-.78V7a1 1 0 0 0 1 1h4.32a4.98 4.98 0 0 0-.79-1.05l-3.48-3.49ZM18 10.48c0-.16-.03-.32-.05-.48H13a3 3 0 0 1-3-3V2.05C9.84 2.03 9.68 2 9.52 2H5a3 3 0 0 0-3 3v14a3 3 0 0 0 3 3h10a3 3 0 0 0 3-3v-8.52Z"
        fill={color || 'currentColor'}
      />
    </svg>
  );
};

export default memo(File);
