import { FC, memo } from 'react';
import { CommonIconProps } from './types';

const Help: FC<CommonIconProps> = ({ size, color = 'var(--help-icon-color, currentColor)', ...rest }) => {
  return (
    <svg
      width={size || 24}
      height={size || 24}
      viewBox="0 0 21 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path d="M20 10.5a9.5 9.5 0 1 1-19 0 9.5 9.5 0 0 1 19 0Z" stroke={color || 'currentColor'} strokeWidth="2" />
      <path
        d="M9.72 10.2v-.1a1.12 1.12 0 0 1 .3-.82c.18-.19.4-.33.66-.4.23-.07.45-.18.64-.33a.74.74 0 0 0 .3-.62.81.81 0 0 0-.22-.6.93.93 0 0 0-.67-.23c-.23-.01-.46.04-.66.14a.6.6 0 0 0-.26.29l-.04.14c-.05.18-.14.34-.26.48a.48.48 0 0 1-.37.16c-.21-.01-.41-.06-.6-.14-.49-.19-.73-.42-.73-.7 0-.12.03-.24.08-.36L8 6.8c.07-.16.16-.3.26-.44.29-.46.73-.8 1.24-.99.35-.13.73-.2 1.1-.2a2.8 2.8 0 0 1 2.9 2.73 2.43 2.43 0 0 1-.64 1.66 3 3 0 0 1-.99.68l-.2.06-.01.31a.56.56 0 0 1-.11.24.5.5 0 0 1-.3.18 2.3 2.3 0 0 1-.56.05c-.2 0-.38-.01-.57-.06a.47.47 0 0 1-.3-.2.76.76 0 0 1-.1-.26 2.42 2.42 0 0 1-.01-.35Zm-.05 2.16c.07-.13.2-.23.34-.27.26-.07.52-.1.78-.09.28-.02.56.06.77.24.19.26.27.59.24.91.01.25-.03.5-.13.72a.6.6 0 0 1-.36.31c-.25.07-.51.1-.77.08-.29.02-.58-.07-.8-.25a.97.97 0 0 1-.2-.7v-.2l.01-.42a1 1 0 0 1 .12-.33Z"
        fill={color || 'currentColor'}
      />
    </svg>
  );
};

export default memo(Help);
