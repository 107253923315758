import { FC, memo, useMemo } from 'react';
import nextId from 'react-id-generator';
import { CommonIconProps } from './types';

const Globe: FC<CommonIconProps> = ({ size, color = 'var(--globe-icon-color, currentColor)', ...rest }) => {
  const id = useMemo(() => nextId(), []);
  return (
    <svg
      width={size || 24}
      height={size || 24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <g clipPath={`url(#${id})`}>
        <path
          d="M12 0a12 12 0 1 0 0 24 12 12 0 0 0 0-24Zm8.65 7h-3.22a19.67 19.67 0 0 0-2.82-4.64c2.54.69 4.71 2.36 6.04 4.64Zm-4.15 5c0 1.02-.17 2.03-.48 3H7.98c-.31-.97-.47-1.98-.48-3 0-1.02.17-2.03.48-3h8.04c.31.97.47 1.98.48 3Zm-7.72 5h6.44A19.62 19.62 0 0 1 12 21.59 19.57 19.57 0 0 1 8.78 17Zm0-10A19.62 19.62 0 0 1 12 2.41 19.57 19.57 0 0 1 15.22 7H8.78Zm.62-4.64A19.68 19.68 0 0 0 6.57 7H3.35A10.03 10.03 0 0 1 9.4 2.36ZM2.46 9H5.9c-.26.98-.4 1.99-.4 3s.14 2.02.4 3H2.46a10 10 0 0 1 0-6Zm.9 8h3.21a19.68 19.68 0 0 0 2.83 4.64A10.03 10.03 0 0 1 3.35 17Zm11.25 4.64A19.67 19.67 0 0 0 17.43 17h3.22a10.03 10.03 0 0 1-6.04 4.64ZM21.54 15H18.1c.26-.98.4-1.99.4-3s-.14-2.02-.4-3h3.44a10 10 0 0 1 0 6Z"
          fill={color || 'currentColor'}
        />
      </g>
      <defs>
        <clipPath id={id}>
          <path fill="#fff" d="M0 0h24v24H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default memo(Globe);
