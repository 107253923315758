import { FC, memo } from 'react';
import { CommonIconProps } from './types';

const Close: FC<CommonIconProps> = ({ size, color = 'var(--close-icon-color, currentColor)', ...rest }) => {
  return (
    <svg
      width={size || 24}
      height={size || 24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        stroke={color || 'currentColor'}
        strokeWidth="1.5"
        strokeLinecap="round"
        d="M6.6 16.97 17.07 6.5M6.86 6.5l10.47 10.47"
      />
    </svg>
  );
};

export default memo(Close);
