import { FC, memo } from 'react';
import { CommonIconProps } from './types';

const Search: FC<CommonIconProps> = ({ size = 18, color = '#374957' }) => {
  return (
    <svg width={size} height={size} fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="m17.8 16.7-4.5-4.4a7.5 7.5 0 1 0-1 1l4.4 4.5a.7.7 0 0 0 1-1ZM7.5 13.5a6 6 0 1 1 0-12 6 6 0 0 1 0 12Z"
        fill={color}
      />
    </svg>
  );
};

export default memo(Search);
