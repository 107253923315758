import { FC, memo } from 'react';
import { COLORS } from 'styles';
import { CommonIconProps } from './types';

const Email: FC<CommonIconProps> = ({ size = 24, color = COLORS.ICON_GREY }) => {
  return (
    <svg width={size} height={size} fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M14.076 10.454c0-.4-.138-.757-.413-1.073-.269-.322-.643-.483-1.122-.483-.748 0-1.397.434-1.948 1.304-.545.864-.817 1.724-.817 2.58 0 .632.141 1.116.423 1.45.282.33.637.494 1.063.494.886 0 1.574-.506 2.066-1.518.499-1.018.748-1.936.748-2.754ZM12.118 19.5c-2.742 0-4.854-.84-6.337-2.523C4.594 15.631 4 14.075 4 12.31c0-2.1.722-3.885 2.165-5.354C7.765 5.318 9.914 4.5 12.61 4.5c2.335 0 4.172.58 5.51 1.74C19.373 7.335 20 8.756 20 10.502c0 1.469-.45 2.725-1.348 3.77-.892 1.037-1.893 1.555-3.001 1.555-.65 0-1.129-.151-1.437-.454a1.519 1.519 0 0 1-.443-1.334c.013-.077.03-.158.05-.241-.348.74-.788 1.266-1.32 1.575a3.278 3.278 0 0 1-1.643.454c-.806 0-1.462-.286-1.967-.86-.499-.573-.748-1.311-.748-2.213 0-1.282.426-2.413 1.279-3.393.86-.979 1.781-1.469 2.765-1.469.67 0 1.22.19 1.653.57.44.38.672.838.699 1.373l.492-1.624h1.446l-1.309 4.234c-.098.328-.17.592-.216.792-.046.2-.069.377-.069.532 0 .29.089.502.266.638.184.128.39.193.62.193.748 0 1.417-.383 2.007-1.15.597-.767.896-1.769.896-3.006 0-1.495-.532-2.668-1.595-3.518-1.062-.857-2.492-1.286-4.29-1.286-2.493 0-4.398.716-5.717 2.146-1.128 1.218-1.692 2.748-1.692 4.59 0 1.612.538 2.965 1.613 4.06 1.26 1.295 3.054 1.943 5.383 1.943a8.977 8.977 0 0 0 3.04-.503 10.253 10.253 0 0 0 2.579-1.372l.65.87c-.591.515-1.474.998-2.648 1.45-1.174.45-2.466.676-3.877.676Z"
        fill={color}
      />
    </svg>
  );
};

export default memo(Email);
