import { FC, memo } from 'react';

type SortDESCProps = {
  size?: number;
};

const SortDESC: FC<SortDESCProps> = ({ size = 24 }) => {
  return (
    <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M11.8079 18.7695L8.09346 14.3121C7.65924 13.7911 8.02976 13 8.70803 13L15.292 13C15.9702 13 16.3408 13.7911 15.9065 14.3121L12.1921 18.7695C12.0921 18.8895 11.9079 18.8895 11.8079 18.7695Z"
        fill="#284573"
      />
      <path
        d="M12.1921 5.23047L15.9065 9.68785C16.3408 10.2089 15.9702 11 15.292 11L8.70803 11C8.02976 11 7.65924 10.2089 8.09346 9.68785L11.8079 5.23047C11.9079 5.11053 12.0921 5.11053 12.1921 5.23047Z"
        fill="#B6C4D8"
      />
    </svg>
  );
};

export default memo(SortDESC);
