import { FC, memo } from 'react';
import { CommonIconProps } from './types';

const EditOutfield: FC<CommonIconProps> = ({ size, color = 'currentColor', ...rest }) => {
  return (
    <svg width={size} height={size} fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M18.58 14.398a.432.432 0 0 0-.42.444v3.94c-.002.734-.566 1.33-1.262 1.33H5.102c-.696 0-1.26-.596-1.261-1.33V7.218c0-.735.565-1.33 1.261-1.332h3.733c.232 0 .42-.198.42-.443A.433.433 0 0 0 8.835 5H5.102c-1.16.001-2.1.994-2.102 2.219V18.78C3.001 20.006 3.942 21 5.102 21h11.796c1.16-.001 2.1-.994 2.102-2.219v-3.94a.432.432 0 0 0-.42-.443Z"
        fill={color}
        stroke={color}
        strokeWidth=".5"
      />
      <path
        d="M19.81 3.629a2.148 2.148 0 0 0-3.036 0L8.26 12.143a.477.477 0 0 0-.123.21l-1.12 4.042a.477.477 0 0 0 .588.588l4.042-1.12a.477.477 0 0 0 .21-.123l8.514-8.514a2.15 2.15 0 0 0 0-3.037l-.56-.56ZM9.3 12.453l6.967-6.968 2.248 2.247-6.969 6.969L9.3 12.453Zm-.45.901 1.796 1.796-2.484.688.688-2.484Zm10.846-6.803-.506.506-2.248-2.247.507-.506a1.193 1.193 0 0 1 1.687 0l.56.56a1.195 1.195 0 0 1 0 1.687Z"
        fill={color}
        stroke={color}
        strokeWidth=".5"
      />
    </svg>
  );
};

export default memo(EditOutfield);
