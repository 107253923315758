import { FC, memo, useMemo } from 'react';
import nextId from 'react-id-generator';
import { CommonIconProps } from './types';

const Upload: FC<CommonIconProps> = ({ size, color = 'var(--upload-icon-color, currentColor)', ...rest }) => {
  const id = useMemo(() => nextId(), []);
  return (
    <svg
      width={size || 24}
      height={size || 24}
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <g clipPath={`url(#${id})`} fill={color || 'currentColor'}>
        <path d="M14.59.88a3 3 0 0 0-4.25 0l-3.2 3.21a1 1 0 0 0 1.4 1.41l2.93-2.93V18a1 1 0 0 0 1 1 1 1 0 0 0 1-1V2.6l2.91 2.9A1 1 0 1 0 17.8 4.1L14.59.88Z" />
        <path d="M23.5 16a1 1 0 0 0-1 1v4a1 1 0 0 1-1 1h-18a1 1 0 0 1-1-1v-4a1 1 0 1 0-2 0v4a3 3 0 0 0 3 3h18a3 3 0 0 0 3-3v-4a1 1 0 0 0-1-1Z" />
      </g>
      <defs>
        <clipPath id={id}>
          <path fill="#fff" transform="translate(.5)" d="M0 0h24v24H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default memo(Upload);
