import { FC, memo } from 'react';
import { CommonIconProps } from './types';

const ArrowDownThin: FC<CommonIconProps> = ({
  size,
  color = 'var(--arrow-down-thin-icon-color, currentColor)',
  ...rest
}) => {
  return (
    <svg
      width={size || 24}
      height={size || 24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="m12 14.14 5.55-5.91a.7.7 0 0 1 1.03 0c.28.3.28.79 0 1.09l-6.06 6.45a.7.7 0 0 1-1.03 0L5.41 9.32a.8.8 0 0 1 0-1.1.7.7 0 0 1 1.03 0L12 14.15Z"
        fill={color || 'currentColor'}
      />
    </svg>
  );
};

export default memo(ArrowDownThin);
