import { FC, memo, useMemo } from 'react';
import nextId from 'react-id-generator';
import { CommonIconProps } from '../types';

const CheckBoxChecked: FC<CommonIconProps> = ({
  size = 28,
  color = 'var(--check-box-checked-icon-color, currentColor)',
}) => {
  const id = useMemo(() => nextId(), []);

  return (
    <svg width={size} height={size} viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath={`url(#${id})`}>
        <path
          d="M18.8591 0.00012207H5.14481C2.30449 0.00012207 0.00195312 2.38794 0.00195312 5.33346V18.6668C0.00195312 21.6123 2.30449 24.0001 5.14481 24.0001H18.8591C21.6994 24.0001 24.002 21.6123 24.002 18.6668V5.33346C24.002 2.38794 21.6994 0.00012207 18.8591 0.00012207Z"
          fill={color}
        />
        <path d="M17.79 8.0002L10.608 15.6842" stroke="white" strokeWidth="3" strokeLinecap="round" />
        <path d="M7.0024 12.0782L10.6084 15.6842" stroke="white" strokeWidth="3" strokeLinecap="round" />
      </g>
      <defs>
        <clipPath id={id}>
          <rect width={size} height={size} fill="white" transform="translate(0.00195312 0.00012207)" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default memo(CheckBoxChecked);
