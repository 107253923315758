import { FC, memo, useMemo } from 'react';
import nextId from 'react-id-generator';
import { CommonIconProps } from './types';

const Refresh: FC<CommonIconProps> = ({ size, color = 'var(--refresh-icon-color, currentColor)', ...rest }) => {
  const id = useMemo(() => nextId(), []);
  return (
    <svg
      width={size || 25}
      height={size || 25}
      viewBox="0 0 25 25"
      fill={color || 'currentColor'}
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <g id="Arrow_Outline" data-name="Arrow Outline" transform="translate(-2.52 -2.529)">
        <path
          id="Path_1122"
          data-name="Path 1122"
          d="M24.3,21.689l-4.21-.468a1.226,1.226,0,0,0-1,.351h0a1.124,1.124,0,0,0,.583,1.9l.889.174a9.591,9.591,0,0,1-5.55,1.742A9.7,9.7,0,0,1,5.633,13.208,1.392,1.392,0,1,0,2.942,12.5a12.465,12.465,0,0,0,19.82,12.954l.271,1.388a1.124,1.124,0,0,0,1.9.583h0a1.225,1.225,0,0,0,.351-1l-.468-4.209A.587.587,0,0,0,24.3,21.689Z"
          transform="translate(0 -0.644)"
          fill="#bec4cb"
        />
        <path
          id="Path_1123"
          data-name="Path 1123"
          d="M15.222,2.529A12.519,12.519,0,0,0,7.469,5.252L7.2,3.864a1.124,1.124,0,0,0-1.9-.583h0a1.225,1.225,0,0,0-.351,1l.468,4.209a.587.587,0,0,0,.519.519l4.21.468a1.226,1.226,0,0,0,1-.351h0a1.124,1.124,0,0,0-.583-1.9l-.889-.174a9.591,9.591,0,0,1,5.55-1.742A9.7,9.7,0,0,1,24.6,17.493a1.393,1.393,0,0,0,.99,1.7,1.41,1.41,0,0,0,.357.046,1.393,1.393,0,0,0,1.345-1.036A12.488,12.488,0,0,0,15.222,2.529Z"
          transform="translate(-0.174)"
          fill="#bec4cb"
        />
      </g>
    </svg>
  );
};

export default memo(Refresh);
