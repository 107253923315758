import { FC, memo } from 'react';
import { CommonIconProps } from './types';

const Burger: FC<CommonIconProps> = ({ width = 23, height = 20, color = 'var(--add-icon-color, currentColor)' }) => {
  return (
    <svg width={width} height={height} viewBox="0 0 23 20" xmlns="http://www.w3.org/2000/svg" data-testid="burger-menu">
      <path d="M21.009,4H1.991a2,2,0,0,1,0-4H21.009a2,2,0,0,1,0,4" fill={color} />
      <path d="M13.784,12H1.991a2,2,0,0,1,0-4H13.784a2,2,0,0,1,0,4" fill={color} />
      <path d="M21.009,20H1.991a2,2,0,0,1,0-4H21.009a2,2,0,0,1,0,4" fill={color} />
    </svg>
  );
};

export default memo(Burger);
