import { FC, memo } from 'react';
import { CommonIconProps } from './types';

const ArrowLeftBold: FC<CommonIconProps> = ({
  size,
  color = 'var(--arrow-left-bold-icon-color, currentColor)',
  ...rest
}) => {
  return (
    <svg
      width={size || 24}
      height={size || 24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M15.95 18.43a2.03 2.03 0 0 0 0-2.77L12 11.5l3.95-4.16a2.03 2.03 0 0 0 0-2.77 1.8 1.8 0 0 0-2.63 0l-5.27 5.54a2.03 2.03 0 0 0 0 2.77l5.27 5.55a1.8 1.8 0 0 0 2.63 0Z"
        fill={color || 'currentColor'}
      />
    </svg>
  );
};

export default memo(ArrowLeftBold);
