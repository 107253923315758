import { FC, memo } from 'react';
import { CommonIconProps } from './types';

const Edit: FC<CommonIconProps> = ({ size, color = 'var(--edit-icon-color, currentColor)', ...rest }) => {
  return (
    <svg
      width={size || 32}
      height={size || 32}
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M9.8 0a2.2 2.2 0 0 0-1.57.65l-7 6.99c-.13.13-.22.3-.27.48L.02 11.4a.47.47 0 0 0 .58.58l3.29-.94c.18-.05.34-.14.47-.28l7-6.99A2.22 2.22 0 0 0 9.79 0Zm0 .94c.31 0 .64.13.88.38.5.5.5 1.28 0 1.78l-.6.61-1.8-1.78.62-.61c.25-.25.57-.38.9-.38ZM7.61 2.6 9.4 4.38 3.7 10.1a.16.16 0 0 1-.08.04l-2.46.7.7-2.46.05-.06L7.6 2.6Z"
        fill={color || 'currentColor'}
      />
    </svg>
  );
};

export default memo(Edit);
