import { FC, memo } from 'react';
import { COLORS } from 'styles';
import { CommonIconProps } from './types';

const VisibilityOff: FC<CommonIconProps> = ({ size = 24, color = COLORS.ICON_GREY }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      data-testid="password-eye-button"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.9202 12.7988C15.9725 12.5407 16 12.2736 16 12C16 9.79086 14.2091 8 12 8C11.7264 8 11.4593 8.02746 11.2012 8.07977L12.3421 9.22069C13.615 9.37575 14.6243 10.385 14.7793 11.6579L15.9202 12.7988ZM9.54012 10.6614C9.32325 11.059 9.2 11.5151 9.2 12C9.2 13.5464 10.4536 14.8 12 14.8C12.4849 14.8 12.941 14.6768 13.3386 14.4599L14.212 15.3332C13.5784 15.7545 12.8179 16 12 16C9.79086 16 8 14.2091 8 12C8 11.1821 8.24547 10.4216 8.66676 9.78799L9.54012 10.6614Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.227 17.3482L15.3457 16.4669C14.2997 17.032 13.1684 17.4 12.0002 17.4C10.3575 17.4 8.78791 16.6725 7.42568 15.6924C6.0682 14.7157 4.96741 13.5246 4.27526 12.6843C4.07017 12.4353 3.95918 12.2981 3.89072 12.1833C3.83765 12.0943 3.82988 12.0508 3.82988 12C3.82988 11.9493 3.83765 11.9057 3.89072 11.8167C3.95918 11.702 4.07017 11.5648 4.27526 11.3158C4.94375 10.5041 5.99341 9.36534 7.28736 8.40856L6.43016 7.55135C5.09532 8.56386 4.02769 9.72884 3.349 10.5529L3.28546 10.6297C2.95985 11.0225 2.62988 11.4206 2.62988 12C2.62988 12.5794 2.95984 12.9775 3.28546 13.3704L3.34899 13.4472C4.07693 14.331 5.25229 15.607 6.72487 16.6665C8.1927 17.7225 10.0071 18.6 12.0002 18.6C13.542 18.6 14.9768 18.0749 16.227 17.3482ZM9.11317 5.99173C10.0142 5.62814 10.9839 5.40002 12.0002 5.40002C13.9933 5.40002 15.8076 6.27752 17.2754 7.33357C18.748 8.39302 19.9234 9.66906 20.6513 10.5529L20.7149 10.6297C21.0405 11.0225 21.3704 11.4206 21.3704 12C21.3704 12.5794 21.0405 12.9775 20.7149 13.3704L20.6513 13.4472C20.1696 14.032 19.492 14.7886 18.662 15.5406L17.8124 14.6909C18.6049 13.9781 19.258 13.2513 19.7251 12.6843C19.9301 12.4353 20.0411 12.2981 20.1096 12.1833C20.1627 12.0943 20.1704 12.0508 20.1704 12C20.1704 11.9493 20.1627 11.9057 20.1096 11.8167C20.0411 11.702 19.9301 11.5648 19.7251 11.3158C19.0329 10.4754 17.9321 9.28431 16.5746 8.30766C15.2124 7.3276 13.6428 6.60002 12.0002 6.60002C11.3346 6.60002 10.6809 6.71949 10.0482 6.9268L9.11317 5.99173Z"
        fill={color}
      />
      <path className="stroke" d="M5 2L21 18" stroke={color} strokeWidth="1.2" />
    </svg>
  );
};

export default memo(VisibilityOff);
