import { FC, memo, useMemo } from 'react';
import nextId from 'react-id-generator';
import { CommonIconProps } from './types';

const Clock: FC<CommonIconProps> = ({ size, color = 'var(--clock-icon-color, currentColor)', ...rest }) => {
  const id = useMemo(() => nextId(), []);
  return (
    <svg
      width={size || 24}
      height={size || 24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <g clipPath={`url(#${id})`} fill={color || 'currentColor'}>
        <path d="M12 0a12 12 0 1 0 0 24 12 12 0 0 0 0-24Zm0 22a10 10 0 1 1 0-20 10 10 0 0 1 0 20Z" />
        <path d="M12 6a1 1 0 0 0-1 1v4.32l-3.37 2.12a1 1 0 1 0 1.06 1.7l3.84-2.4a1 1 0 0 0 .47-.86V7a1 1 0 0 0-1-1Z" />
      </g>
      <defs>
        <clipPath id={id}>
          <path fill="#fff" d="M0 0h24v24H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default memo(Clock);
