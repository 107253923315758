import { FC, memo } from 'react';
import { CommonIconProps } from './types';

const Briefcase: FC<CommonIconProps> = ({ size, color = 'var(--briefcase-icon-color, currentColor)', ...rest }) => {
  return (
    <svg
      width={size || 24}
      height={size || 24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M19 4h-1.1A5 5 0 0 0 13 0h-2a5 5 0 0 0-4.9 4H5a5 5 0 0 0-5 5v10a5 5 0 0 0 5 5h14a5 5 0 0 0 5-5V9a5 5 0 0 0-5-5Zm-8-2h2a3 3 0 0 1 2.8 2H8.2A3 3 0 0 1 11 2ZM5 6h14a3 3 0 0 1 3 3v3H2V9a3 3 0 0 1 3-3Zm14 16H5a3 3 0 0 1-3-3v-5h9v1a1 1 0 0 0 2 0v-1h9v5a3 3 0 0 1-3 3Z"
        fill={color || 'currentColor'}
      />
    </svg>
  );
};

export default memo(Briefcase);
