import { FC, memo } from 'react';
import { CommonIconProps } from './types';

const CloseBold: FC<CommonIconProps> = ({ size, color = 'var(--close-bold-icon-color, currentColor)', ...rest }) => {
  return (
    <svg
      width={size || 15}
      height={size || 15}
      viewBox="0 0 15 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="m4.305 12.565 8.262-8.262A2.028 2.028 0 0 0 9.7 1.434L1.436 9.697a2.028 2.028 0 0 0 2.869 2.868Z"
        fill={color || 'currentColor'}
      />
      <path
        d="M1.436 4.303 9.7 12.566a2.028 2.028 0 1 0 2.869-2.869L4.305 1.435a2.028 2.028 0 1 0-2.869 2.868Z"
        fill={color || 'currentColor'}
      />
    </svg>
  );
};

export default memo(CloseBold);
