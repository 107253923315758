import { FC, memo } from 'react';
import { CommonIconProps } from './types';

const ImageUpload: FC<CommonIconProps> = ({
  size,
  color = 'var(--image-upload-icon-color, currentColor)',
  ...rest
}) => {
  return (
    <svg
      width={size || 32}
      height={size || 32}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M27 1H5a5.07 5.07 0 0 0-5 5.04V23.7c0 1.34.53 2.62 1.47 3.57.93.94 2.2 1.47 3.53 1.47h17.06c.33 0 .65-.13.89-.36a1.27 1.27 0 0 0-.89-2.16h-4.6l-2.88-3.76 9.11-11.68 6.13 6.83a1.25 1.25 0 0 0 2.18-.84V6.04c0-1.33-.53-2.62-1.47-3.56A4.98 4.98 0 0 0 27 1Zm2.5 12.49-4.95-5.52a1.25 1.25 0 0 0-1.5-.28c-.15.09-.3.2-.4.35L13 20.39l-3.27-4.28a1.25 1.25 0 0 0-1.54-.36c-.17.09-.32.21-.44.36l-2.75 3.61a1.27 1.27 0 0 0 .25 1.74 1.24 1.24 0 0 0 1.73-.2l1.76-2.3 5.55 7.25H5a2.53 2.53 0 0 1-2.5-2.52V6.04A2.53 2.53 0 0 1 5 3.52h22a2.54 2.54 0 0 1 2.5 2.52v7.45Z"
        fill={color || 'currentColor'}
      />
      <path
        d="M8.75 5.54a3.73 3.73 0 0 0-3.46 2.33 3.81 3.81 0 0 0 2.73 5.16 3.72 3.72 0 0 0 3.85-1.6 3.8 3.8 0 0 0-.47-4.78c-.7-.71-1.66-1.11-2.65-1.11Zm0 5.04a1.24 1.24 0 0 1-1.16-.78 1.27 1.27 0 0 1 .92-1.72 1.24 1.24 0 0 1 1.28.54 1.27 1.27 0 0 1-.16 1.6c-.23.23-.55.36-.88.36ZM29.28 20.1l-.01-.02a3.12 3.12 0 0 0-4.42 0l-2.42 2.4a1.27 1.27 0 0 0 .87 2.16c.34 0 .65-.13.89-.37l1.61-1.6v7.07A1.27 1.27 0 0 0 27.05 31a1.27 1.27 0 0 0 1.25-1.26V22.7l1.55 1.61a1.25 1.25 0 0 0 2.15-.85c0-.34-.12-.66-.35-.9l-2.37-2.46Z"
        fill={color || 'currentColor'}
      />
    </svg>
  );
};

export default memo(ImageUpload);
