import { FC, memo } from 'react';
import { CommonIconProps } from './types';

const UserInCircle: FC<CommonIconProps> = ({
  size,
  color = 'var(--user-in-circle-icon-stroke-color, currentColor)',
  ...rest
}) => {
  return (
    <svg
      width={size || 24}
      height={size || 24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <circle cx="12" cy="10" r="3" stroke={color || 'currentColor'} strokeLinecap="round" />
      <circle cx="12" cy="12" r="9" stroke={color || 'currentColor'} />
      <path
        d="M18 18.7a5.1 5.1 0 0 0-2.22-2.67A7.24 7.24 0 0 0 12 15c-1.37 0-2.7.36-3.78 1.03A5.1 5.1 0 0 0 6 18.71"
        stroke={color || 'currentColor'}
        strokeLinecap="round"
      />
    </svg>
  );
};

export default memo(UserInCircle);
